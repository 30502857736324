import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { BrowserTracing } from "@sentry/tracing";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import "./App.css";

import * as PrestAPI from "./service/api/PrestAPI";

import ProdutoList from "./pages/Produto/ProdutoList";
import Produto from "./pages/Produto/Produto";
import ProdutoForm from "./pages/Produto/ProdutoForm";
import Login from "./pages/Login/Login";
import Pedidos from "./pages/Pedidos/Pedidos";
import ProdutoGrupoForm from "./pages/ProdutoGrupo/ProdutoGrupoForm";
import TaxaEntregaList from "./pages/TaxaEntrega/TaxaEntregaList";
import TaxaEntregaForm from "./pages/TaxaEntrega/TaxaEntregaForm";
import PrivateRoute from "./Router/PrivateRoute";
import * as dotenv from "dotenv";
import * as Sentry from "@sentry/react";
import Relatorios from "./pages/Relatorios/Relatorios";
import Config from "./pages/Config/Config";
import { useAuthContext } from "./context/AuthContext";
import Parceiro from "./pages/Parceiro/Parceiro";
import NotFound from "./pages/NotFound/NotFound";
import { PartnerRoute } from "./Router/PartnerRouter";

dotenv.config();

if (process.env.REACT_APP_SENTRY_DNS) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
}
const validHostnames = [
  "parceiros.vempraka.com.br",
  "parceiros.anoteja.com.br",
  "parceiros.mrstecno.com.br",
  "parceiros-hom.anoteja.com.br",
  "localhost:3002",
];

const App = () => {
  const [taxa, setTaxa] = useState({});
  const [isNewTaxa, setIsNewTaxa] = useState(true);
  const [imagem, setImagem] = useState(null);

  const hostname = window.location.hostname;
  const url = window.location.href;

  const navigate = useNavigate();

  const { estabelecimento } = useAuthContext();

  const clearCacheData = async () => {
    try {
      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map((name) => caches.delete(name)));
    } catch (error) {
      console.error("Failed to clear cache data", error);
    }
  };

  const verificaHostname = () => {
    const mapping = {
      "painel.vempraka.com.br": {
        title: "Painel - VemPraKa",
        favicon: "https://biblioteca.mrstecno.com.br/vempraka/logo/favicon.ico",
      },
      "painel.donfrango.com.br": {
        title: "Painel - Don Frango",
        favicon:
          "https://biblioteca.mrstecno.com.br/vempraka/empresas/Don%20Frango.jpg",
      },
      "painel.mrsdelivery.vempraka.com.br": {
        title: "Painel - MRS Delivery",
        favicon:
          "https://img.freepik.com/vetores-premium/entregador-montando-a-ilustracao-de-scooter-vermelho_9845-14.jpg?w=740",
      },
    };

    const defaultConfig = {
      title: hostname,
      favicon: "https://biblioteca.mrstecno.com.br/vempraka/logo/favicon.ico",
    };

    const siteConfig = mapping[hostname] || defaultConfig;

    let favicon = document.querySelector("link[rel='shortcut icon']");
    if (!favicon) {
      favicon = document.createElement("link");
      favicon.rel = "shortcut icon";
      document.head.appendChild(favicon);
    }
    favicon.href = siteConfig.favicon;

    document.title = siteConfig.title;

    setImagem(siteConfig.favicon);

    clearCacheData().catch((error) =>
      console.error("Failed to clear cache data", error)
    );
  };
  useEffect(() => {
    verificaHostname();
  }, []);

  const setNovaTaxa = () => {
    setIsNewTaxa(true);
  };

  const getTaxaEntregaNovo = () => {
    if (!estabelecimento || !estabelecimento.id_estabelecimento) {
      return;
    }
    return {
      id_taxa: null,
      id_estabelecimento: estabelecimento.id_estabelecimento,
      bairro: "",
      cep: "",
    };
  };

  const addTaxaEntrega = (taxa) => {
    PrestAPI.add("Taxa", taxa)
      .then((res) => {
        if (res.data.error) {
          toast.error(`Erro: ${res.data.error}`);
          return;
        }
        toast.success("Salvo!", { id: "sucessoTaxa" });
        setTaxa(res.data);
        setIsNewTaxa(false);
        navigate("/taxa_entrega-list");
      })
      .catch((error) => {
        console.error("Erro ao adicionar taxa:", error);
        toast.error("Não foi possível cadastrar a taxa. Tente novamente");
      });
  };

  const updateTaxaEntrega = (idTaxa, taxa) => {
    PrestAPI.update("Taxa", `?id_taxa=${idTaxa}`, taxa)
      .then((res) => {
        toast.success("Salvo!", {
          id: "sucessoTaxa",
        });
        setTaxa(res.data);
      })
      .catch((error) => {
        toast.error("Não foi possível atualizar a taxa..\nTente novamente");
      });
  };

  const ParceiroRoute = () => {
    const { parceiroName } = useParams();
    return <Parceiro name={parceiroName} />;
  };

  if (
    validHostnames.some((domain) => url.includes(domain) || hostname === domain)
  ) {
    return <PartnerRoute />;
  }

  return (
    <>
      <Toaster />

      <Routes>
        <Route path="/parceiros/:parceiroName" element={<ParceiroRoute />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Navigate to="/produto" />
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<Login imagem={imagem} />} />

        <Route
          path="/produto"
          element={
            <PrivateRoute>
              <Produto />
            </PrivateRoute>
          }
        />

        <Route
          path="/config"
          element={
            <PrivateRoute>
              <Config />
            </PrivateRoute>
          }
        />
        <Route
          path="/produto-list"
          element={
            <PrivateRoute>
              <ProdutoList />
            </PrivateRoute>
          }
        />
        <Route
          path="/produto-add"
          element={
            <PrivateRoute>
              <ProdutoForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/produto-edit"
          element={
            <PrivateRoute>
              <ProdutoForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/produto_grupo-add"
          element={
            <PrivateRoute>
              <ProdutoGrupoForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/produto_grupo-edit"
          element={
            <PrivateRoute>
              <ProdutoGrupoForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/pedidos"
          element={
            <PrivateRoute>
              <Pedidos />
            </PrivateRoute>
          }
        />
        <Route
          path="/taxa_entrega-list"
          element={
            <PrivateRoute>
              <TaxaEntregaList
                estabelecimento={estabelecimento}
                editTaxa={(taxa) => {
                  setTaxa(taxa);
                  setIsNewTaxa(false);
                  navigate("/taxa_entrega-edit");
                }}
                setNovaTaxa={() => setNovaTaxa()}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/taxa_entrega-add"
          element={
            <PrivateRoute>
              <TaxaEntregaForm
                taxa={getTaxaEntregaNovo()}
                saveTaxa={(idTaxa, taxa) => {
                  addTaxaEntrega(taxa);
                }}
                isNewTaxa={isNewTaxa}
                setNovaTaxa={() => setNovaTaxa()}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/taxa_entrega-edit"
          element={
            <PrivateRoute>
              <TaxaEntregaForm
                taxa={taxa}
                saveTaxa={updateTaxaEntrega}
                isNewTaxa={isNewTaxa}
                setNovaTaxa={() => setNovaTaxa()}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/relatorios"
          element={
            <PrivateRoute>
              <Relatorios />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;
