import { useState, useEffect, useCallback } from "react";
import { apiClient } from "../../../service/api/PrestAPI";
import { updateTaxaEntrega, addTaxaEntrega } from "../apiServices";
import { useAuthContext } from "../../../context/AuthContext";

const useGetTaxas = () => {
  const { estabelecimento } = useAuthContext();
  const [taxas, setTaxas] = useState([]);
  const [loading, setLoading] = useState(false);

  const getTaxas = useCallback(async () => {
    setLoading(true);
    try {
      const response = await apiClient.get(
        `Taxa?id_estabelecimento=${estabelecimento.id_estabelecimento}`
      );
      setTaxas(response.data);
    } catch (error) {
      console.error("Erro ao carregar as taxas:", error);
    } finally {
      setLoading(false);
    }
  }, [estabelecimento.id_estabelecimento]);

  useEffect(() => {
    if (estabelecimento.id_estabelecimento) {
      getTaxas();
    }
  }, [estabelecimento.id_estabelecimento, getTaxas]);

  return { taxas, loading, refreshTaxas: getTaxas };
};

export { useGetTaxas };
