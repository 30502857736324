import React, { useState } from "react";
import Perfil from "../Perfil/Perfil";
import Empresas from "../Estabelecimento/Estabelecimentos";
import FormCadastro from "../Estabelecimento/FormCadastro";
import Planos from "../Planos/Planos";
import Usuarios from "../Usuarios/Usuarios";
import PageSlide from "../Slide/PageSldie";
import { useAuthContext } from "../../context/AuthContext";
import Desconto from "../Desconto/Desconto";

function Config() {
  const [activeTab, setActiveTab] = useState("perfil");
  const { userData } = useAuthContext();

  const renderTabContent = () => {
    switch (activeTab) {
      case "perfil":
        return <Perfil />;
      case "imagensSlide":
        return <PageSlide />;
      case "cadastro":
        return <FormCadastro />;
      case "desconto":
        return <Desconto />;
      case "usuarios":
        return <Usuarios />;
      case "empresas":
        return <Empresas />;
      case "planos":
        return <Planos />;
      case "ajuda":
        return <div>Ajuda Content</div>;
      default:
        return null;
    }
  };

  const renderTab = (tab, label, allowedRoles = []) => {
    if (
      allowedRoles.length > 0 &&
      !allowedRoles.includes(userData.permissionLevel)
    ) {
      return null;
    }

    return (
      <li className="nav-item" role="presentation">
        <button
          className={`nav-link cursor-pointer ${
            activeTab === tab ? "active" : "text-dark"
          }`}
          onClick={() => setActiveTab(tab)}
        >
          {label}
        </button>
      </li>
    );
  };
  return (
    <div className="container-fluid container">
      <div className="my-4">
        <h2 className="text-center" style={{ color: "#FF5722" }}>
          Configurações
        </h2>
        <ul className="nav nav-tabs">
          {renderTab("perfil", "Perfil")}
          {renderTab("imagensSlide", "Imagens Slide")}
          {renderTab("cadastro", "Meus dados", ["super", "admin"])}
          {renderTab("desconto", "Desconto Geral", ["super", "admin"])}
          {renderTab("usuarios", "Usuarios", ["super", "admin"])}
          {renderTab("empresas", "Empresas", ["super"])}
          {renderTab("planos", "Planos", ["super"])}
          {renderTab("ajuda", "Ajuda")}
        </ul>
        <div className="tab-content mt-3">{renderTabContent()}</div>
      </div>
    </div>
  );
}

export default Config;
