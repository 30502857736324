export const validateFields = (estabelecimento) => {
  const { razao_social, cnpj, email } = estabelecimento;
  const errors = {};

  if (!razao_social) errors.razao_social = "Razão social é obrigatória.";
  if (!cnpj) errors.cnpj = "CNPJ é obrigatório.";
  if (!email) errors.email = "Email é obrigatório.";

  const cnpjPattern = /^\d{14}$/;
  if (!cnpjPattern.test(cnpj.replace(/\D/g, ""))) {
    errors.cnpj = "CNPJ inválido.";
  }

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(email)) {
    errors.email = "Email inválido.";
  }

  return errors;
};

export const validateImage = (image) => {
  const validTypes = ["image/jpeg", "image/png"];
  const validExtensions = ["jpg", "jpeg", "png"];
  const maxSize = 10 * 1024 * 1024;

  if (image.size > maxSize) {
    return "O tamanho da imagem não pode exceder 10MB.";
  }

  const extension = image.name.split(".").pop().toLowerCase();
  if (!validExtensions.includes(extension)) {
    return "A extensão da imagem é inválida. Permitido apenas arquivos JPG, JPEG e PNG.";
  }

  return null;
};
