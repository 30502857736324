import { useEffect, useState, useCallback } from 'react';
import { apiClient } from "../../../service/api/PrestAPI";

export const useGetBanners = (storeUid) => {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchBanners = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(`/store/${storeUid}/banner`);
      setBanners(data);
      setError(null);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [storeUid]);

  useEffect(() => {
    if (storeUid) {
      fetchBanners();
    }
  }, [storeUid, fetchBanners]);

  return { banners, loading, error, refetch: fetchBanners };
};
