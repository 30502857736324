import { useState, useEffect } from "react";
import { apiClient } from "../../service/api/PrestAPI";
import { useAuthContext } from "../../context/AuthContext";

export const useGetUsuarios = () => {
  const [loading, setLoading] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const { estabelecimento } = useAuthContext();

  const fetchUsuarios = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get(
        `/user/${estabelecimento.id_estabelecimento}`
      );
      setUsuarios(response.data);
    } catch (error) {
      console.error("Failed to fetch users:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsuarios();
  }, []);

  return { usuarios, loading, fetchUsuarios };
};
