import React, { useState, useEffect } from "react";
import * as PrestAPI from "../../service/api/PrestAPI";
import toast, { Toaster } from "react-hot-toast";
import FormEstabelecimento from "./FormEstabelecimento";
import { Pencil, Trash3 } from "react-bootstrap-icons";

const Empresas = () => {
  const [allEstabelecimentos, setAllEstabelecimentos] = useState([]);
  const [showCampos, setShowCampos] = useState(false);
  const [selectedEstabelecimentoId, setSelectedEstabelecimentoId] =
    useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const limit = 20;

  useEffect(() => {
    fetchAllEstabelecimentos();
  }, []);

  const fetchAllEstabelecimentos = async () => {
    setIsLoading(true);
    try {
      const res = await PrestAPI.get("stores");
      if (res.status === "SUCESSO" && res.estabelecimentos) {
        const combinedEstabelecimentos = res.estabelecimentos.flat();
        setAllEstabelecimentos(combinedEstabelecimentos);
      } else {
        toast.error("Erro ao buscar os estabelecimentos", {
          id: "fechaEstabelecimentos",
        });
      }
    } catch (error) {
      toast.error(error.message || "Erro ao buscar os estabelecimentos", {
        id: "fechaEstabelecimentos",
      });
    } finally {
      setIsLoading(false);
    }
  };
  const filteredEstabelecimentos = allEstabelecimentos
    .filter(
      (estabelecimento) =>
        estabelecimento.razao_social
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        estabelecimento.nome_fantasia
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => b.id_estabelecimento - a.id_estabelecimento);

  const startIndex = (page - 1) * limit;
  const endIndex = startIndex + limit;
  const estabelecimentosPaginados = filteredEstabelecimentos.slice(
    startIndex,
    endIndex
  );
  const totalPages = Math.ceil(filteredEstabelecimentos.length / limit);

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const toggleForm = (isEditing = false, estabelecimentoId = null) => {
    setShowCampos(true);
    setIsEditing(isEditing);
    setSelectedEstabelecimentoId(estabelecimentoId);

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCancel = () => {
    setShowCampos(false);
  };

  const handleDelete = async (id) => {};

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  return (
    <section className="container-fluid">
      <div className="w-100 py-4">
        {!showCampos && (
          <button
            type="button"
            className="btn btn-vk"
            onClick={() => toggleForm(false)}
          >
            Cadastrar Nova Empresa
          </button>
        )}
        {showCampos && (
          <FormEstabelecimento
            estabelecimentoId={selectedEstabelecimentoId}
            isEditing={isEditing}
            onCancel={handleCancel}
          />
        )}
        <div className="row my-2 mx-0 px-0">
          <div className="col-lg-6 px-0">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                name="search"
                placeholder="Pesquisar"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <button className="btn btn-vk">Pesquisar</button>
            </div>
          </div>
        </div>
        <div className="table-responsive mt-4">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Telefone</th>
                <th>Data do Cadastro</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="5">
                    <div className="loader" id="loader">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </td>
                </tr>
              ) : estabelecimentosPaginados.length > 0 ? (
                estabelecimentosPaginados.map((estabelecimento) => (
                  <tr key={estabelecimento.id_estabelecimento}>
                    <td>{estabelecimento.razao_social}</td>
                    <td>{estabelecimento.email}</td>
                    <td>{estabelecimento.telefone}</td>
                    <td></td>
                    <td className="d-flex justify-content-evenly">
                      <button
                        className="btn btn-outline-light"
                        title="Editar"
                        onClick={() => toggleForm(true, estabelecimento.uid)}
                      >
                        <Pencil color="#FF5722" />
                      </button>

                      <button
                        className="btn btn-outline-light"
                        title="Excluir"
                        onClick={() => handleDelete(estabelecimento.id)}
                      >
                        <Trash3 color="#FF5722" />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">Nenhum estabelecimento encontrado.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="pagination-buttons mt-3">
          <button
            className="btn btn-secondary mx-1"
            onClick={handlePreviousPage}
            disabled={page === 1}
          >
            Anterior
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              className={`btn ${
                page === index + 1 ? "btn-primary" : "btn-secondary"
              } mx-1`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <button
            className="btn btn-secondary mx-1"
            onClick={handleNextPage}
            disabled={page === totalPages}
          >
            Próximo
          </button>
        </div>
      </div>
      <Toaster />
    </section>
  );
};

export default Empresas;
