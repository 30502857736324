import { useState, useEffect } from "react";
import * as PrestAPI from "../../../service/api/PrestAPI";

const useSegmentos = () => {
  const [categorias, setCategorias] = useState([]);
  const [isLoadingCategorias, setIsLoadingCategorias] = useState(false);
  const [error, setError] = useState(null);

  const getSegmentos = async () => {
    setIsLoadingCategorias(true);
    try {
      const result = await PrestAPI.get("Categorias");

      if (result && result.categorias) {
        setCategorias(result.categorias);
      } else {
        setError("Nenhuma categoria encontrada.");
      }
    } catch (error) {
      console.error("Erro ao buscar categorias:", error);
      setError(error.message || "Erro ao buscar categorias.");
    } finally {
      setIsLoadingCategorias(false);
    }
  };

  useEffect(() => {
    getSegmentos();
  }, []);

  return { categorias, isLoadingCategorias, error, getSegmentos };
};

export default useSegmentos;
