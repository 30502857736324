import { useState } from "react";
import { apiClient } from "../../../service/api/PrestAPI";

const useUpdateCurrentEstabelecimento = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState(null);

  const updateEstabelecimento = async (estabelecimento) => {
    setIsUpdating(true);
    setError(null);

    try {
      const result = await apiClient.put(
        `Estabelecimento`,
        estabelecimento
      );

      if (result.status === 200 || result.status === 204) {
        return result.data;
      } else {
        throw new Error(
          result.data.message || "Erro ao atualizar o estabelecimento."
        );
      }
    } catch (err) {
      setError(err.message || "Erro desconhecido.");
      return null;
    } finally {
      setIsUpdating(false);
    }
  };

  return { updateEstabelecimento, isUpdating, error };
};

export default useUpdateCurrentEstabelecimento;
