import React, { useState, useCallback } from "react";
import * as PrestAPI from "../../service/api/PrestAPI";
import "./Pedidos.css";
import { toast } from "react-hot-toast";
import PedidoSelecionado from "./PedidoSelecionado";
import useMediaQuery from "../../utils/useMediaQuery";
import { useAuthContext } from "../../context/AuthContext";
import { useLoadPedidos } from "../../features/Pedidos/hooks/useLoadPedidos";

const Pedidos = () => {
  const [pedido, setPedido] = useState(null);
  const [itens, setItens] = useState([]);
  const [modoPedido, setModoPedido] = useState("ABERTO");
  const [subtotal, setSubtotal] = useState(0);
  const [expandedPedidoId, setExpandedPedidoId] = useState(null);
  const [loadingPedido, setLoadingPedido] = useState(false);
  const isAboveMediumScreens = useMediaQuery("(min-width:768px)");
  const { estabelecimento } = useAuthContext();
  const { pedidos, loadPedidos } = useLoadPedidos(estabelecimento);

  const fetchPedido = useCallback(async (pedidoItem) => {
    setLoadingPedido(true);
    try {
      const value = await PrestAPI.get(
        "Pedidos",
        `id_pedido=${pedidoItem.id_pedido}`
      );
      setPedido(pedidoItem);
      setItens(value);
      setSubtotal(calculaSubtotal(value));
    } catch (error) {
      toast.error("Erro ao carregar o pedido.");
      console.error("Erro ao carregar pedido:", error);
    } finally {
      setLoadingPedido(false);
    }
  }, []);

  const calculaSubtotal = useCallback((itens) => {
    return itens.reduce((soma, item) => {
      const totalSubItens = item.vr_total_sub_itens || 0;
      const preco = (item.vr_unitario + totalSubItens) * item.quantidade;
      return soma + preco;
    }, 0);
  }, []);

  const updateStatusPedido = async (pedidoItem, statusPedido) => {
    try {
      pedidoItem.status_pedido = statusPedido;
      const response = await PrestAPI.update(
        "Pedido",
        `?id_pedido=${pedidoItem.id_pedido}`,
        pedidoItem
      );

      if ([200, 204].includes(response.status)) {
        await loadPedidos();
        setPedido(pedidoItem);
      } else {
        toast.error("Erro ao atualizar o status do pedido.");
      }
    } catch (error) {
      toast.error("Erro ao atualizar o status do pedido.");
      console.error("Erro ao atualizar o pedido:", error);
    }
  };

  const renderStatus = (statusPedido, isTitle = false) => {
    const statusConfig = {
      CONFIRMADO: { border: "1px solid DeepSkyBlue", text: "Confirmado" },
      ENVIADO: { border: "1px solid gray", text: "Enviado" },
      ENTREGUE: { border: "1px solid green", text: "Entregue" },
      CANCELADO: { border: "1px solid red", text: "Cancelado" },
      default: { border: "1px solid orange", text: "Pendente" },
    };

    const { border, text } = statusConfig[statusPedido] || statusConfig.default;

    const Tag = isTitle ? "h4" : "div";
    return (
      <Tag
        className={isTitle ? "" : "col-12"}
        style={{ border, borderRadius: "5px", textAlign: "center" }}
      >
        {text}
      </Tag>
    );
  };

  const pedidosFiltrados = pedidos.filter((p) =>
    modoPedido === "ABERTO"
      ? !["CANCELADO", "ENTREGUE"].includes(p.status_pedido)
      : ["CANCELADO", "ENTREGUE"].includes(p.status_pedido)
  );
  const pedidosAbertos = pedidos.filter(
    (p) => !["CANCELADO", "ENTREGUE"].includes(p.status_pedido)
  );

  const pedidosFinalizados = pedidos.filter((p) =>
    ["CANCELADO", "ENTREGUE"].includes(p.status_pedido)
  );

  return (
    <div className="container-fluid page-pedido">
      <div className="row">
        <div
          className="card col-sm-4 col-12 overflow-auto"
          style={{ height: "85vh", borderRadius: "3px 0 0 3px" }}
        >
          <div className="row mb-4 card-header">
            <div className="col-6 text-center">
              <button
                className={`btn w-100 btn-light bg-light ${
                  modoPedido === "ABERTO" ? "active" : ""
                }`}
                onClick={() => setModoPedido("ABERTO")}
              >
                Aberto
              </button>
              <span>{pedidosAbertos.length}</span>
            </div>

            <div className="col-6 text-center">
              <button
                className={`btn btn-light bg-light w-100 ${
                  modoPedido === "FINALIZADO" ? "active" : ""
                }`}
                onClick={() => setModoPedido("FINALIZADO")}
              >
                Finalizado
              </button>
              <span>{pedidosFinalizados.length}</span>
            </div>
          </div>

          {pedidosFiltrados.map((pedidoItem) => (
            <>
              <div
                key={pedidoItem.id_pedido}
                className={`card clickable pedido mt-3 ${
                  pedido && pedidoItem.id_pedido === pedido.id_pedido
                    ? "pedidoSelecionado"
                    : ""
                }`}
                onClick={() => {
                  fetchPedido(pedidoItem);
                  setExpandedPedidoId(
                    expandedPedidoId === pedidoItem.id_pedido
                      ? null
                      : pedidoItem.id_pedido
                  );
                }}
                style={{
                  userSelect: "none",
                  backgroundColor:
                    pedidoItem.status_pedido === "PENDENTE"
                      ? "#fcee9f"
                      : pedidoItem.status_pedido === "ENTREGUE"
                      ? "#abfc8b"
                      : pedidoItem.status_pedido === "CANCELADO"
                      ? "#fc9797"
                      : "",
                }}
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-2">{pedidoItem.id_pedido}</div>
                    <div className="col-5 p-0">{pedidoItem.nome_cliente}</div>
                    <div className="col-5">
                      {renderStatus(pedidoItem.status_pedido)}
                    </div>
                  </div>
                </div>
              </div>

              {expandedPedidoId === pedidoItem.id_pedido &&
                !isAboveMediumScreens && (
                  <div className="card p-2 position-relative">
                    {loadingPedido ? (
                      <div className="loader" id="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    ) : (
                      <PedidoSelecionado
                        pedido={pedido}
                        subtotal={subtotal}
                        documentoHide={pedido.documento}
                        itens={itens}
                        renderStatus={renderStatus}
                        updateStatusPedido={updateStatusPedido}
                      />
                    )}
                  </div>
                )}
            </>
          ))}
        </div>

        {pedido && isAboveMediumScreens && (
          <div
            className="card col-sm-8 col-12 pb-3 overflow-auto"
            style={{ height: "85vh", padding: "30px 80px" }}
          >
            {loadingPedido ? (
              <div className="loader" id="loader">
                <span></span>
                <span></span>
                <span></span>
              </div>
            ) : (
              <PedidoSelecionado
                pedido={pedido}
                subtotal={subtotal}
                documentoHide={pedido.documento}
                itens={itens}
                renderStatus={renderStatus}
                updateStatusPedido={updateStatusPedido}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Pedidos;
