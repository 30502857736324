import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { apiClient } from "../../../service/api/PrestAPI";

export function useDeleteTaxa() {
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteTaxa = async (taxa, refreshTaxas) => {
    setIsDeleting(true);
    try {
      const response = await apiClient.delete("Taxa", {
        data: {
          id_taxa: taxa.id_taxa,
          id_estabelecimento: taxa.id_estabelecimento,
        },
      });

      if (response && response.status === 202) {
        toast.success(
          `Taxa ${taxa.nome || taxa.id_taxa} excluída com sucesso`,
          {
            id: "taxa",
          }
        );
        if (refreshTaxas) refreshTaxas();
      } else {
        toast.error(`Falha ao excluir a taxa ${taxa.nome || taxa.id_taxa}`, {
          id: "taxa",
        });
      }
    } catch (error) {
      toast.error(
        `Não foi possível excluir a taxa ${
          taxa.nome || taxa.id_taxa
        }. Tente mais tarde.`,
        {
          id: "taxa",
        }
      );
    } finally {
      setIsDeleting(false);
      toast.dismiss("taxa");
    }
  };

  return {
    deleteTaxa,
    isDeleting,
  };
}
