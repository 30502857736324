import React from "react";
import "./MobilePreview.css";

const MobilePreview = ({ colors, imageUrl, nomeFantasia }) => {
  const renderInputField = (placeholder, type, color) => (
    <div className="fieldWrapper">
      <svg
        viewBox="0 0 24 24"
        width="24"
        aria-hidden="true"
        focusable="false"
        fill="currentColor"
        color={color}
        xmlns="http://www.w3.org/2000/svg"
        className="StyledIconBase-sc-ea9ulj-0 kczbJK"
      >
        {type === "email" ? (
          <path d="M19 4H5a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zm-.67 2L12 10.75 5.67 6zM19 18H5a1 1 0 0 1-1-1V7.25l7.4 5.55a1 1 0 0 0 .6.2 1 1 0 0 0 .6-.2L20 7.25V17a1 1 0 0 1-1 1z" />
        ) : (
          <path d="M12 2C9.243 2 7 4.243 7 7v2H6c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-9c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v2H9V7zm9.002 13H13v-2.278c.595-.347 1-.985 1-1.722 0-1.103-.897-2-2-2s-2 .897-2 2c0 .736.405 1.375 1 1.722V20H6v-9h12l.002 9z" />
        )}
      </svg>
      <input
        placeholder={placeholder}
        className="login-input"
        type={type}
        readOnly
      />
    </div>
  );

  return (
    <div className="mobile-frame">
      <div className="notch mb-4">
        <div className="camera"></div>
        <div className="speaker"></div>
      </div>
      <div className="d-flex flex-column gap-3 p-3 w-auto mt-4">
        <div className="d-flex flex-column gap-3 w-auto mt-4">
          <h5 className="text-center fs-4">{nomeFantasia}</h5>
          {imageUrl && (
            <img
              src={imageUrl}
              alt="Preview"
              className="img-fluid d-block mx-auto"
              style={{ maxWidth: "150px" }}
            />
          )}
          <span className="text-center w-75 mx-auto">
            Informe o seu e-mail e senha para continuar
          </span>
          {renderInputField("Email", "email", colors.a)}
          {renderInputField("Senha", "password", colors.a)}
          <h4 className="fs-6">Esqueceu a senha?</h4>
          <button
            className="login-button"
            style={{ backgroundColor: colors.a }}
          >
            Entrar
          </button>
          <div className="googleButton" type="button">
            <img
              src="https://vempraka.com.br/google.svg"
              alt="GoogleLogo"
              width="25px"
            />
            <span>Entre com o Google</span>
          </div>
        </div>

        <div className="d-flex g-2 mx-auto">
          <span className="pe-1">Novo no VemPraKa?</span>
          <span style={{ color: colors.a }}>Cadastre-se</span>
        </div>
      </div>
    </div>
  );
};

MobilePreview.defaultProps = {
  colors: {
    a: "#FF5722",
    b: "#FE8862",
    c: "#FFBAA4",
    d: "#FFE6DE",
    e: "#FFE6DE",
  },
};

export default MobilePreview;
