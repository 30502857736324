import { useState, useEffect } from "react";
import benefitsService from "../benefitsService";

export const useBenefitsByStore = (storeUid, updateTrigger) => {
  const [benefits, setBenefits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!storeUid) {
      setLoading(false);
      setError("Store UID is missing");
      return;
    }

    const controller = new AbortController();
    const fetchBenefits = async () => {
      setLoading(true);
      try {
        const data = await benefitsService.getBenefitsByStore(storeUid, { signal: controller.signal });
        setBenefits(data);
      } catch (err) {
        if (err.name !== "AbortError") {
          setError(err);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchBenefits();

    return () => controller.abort();
  }, [storeUid, updateTrigger]);

  return { benefits, loading, error };
};
