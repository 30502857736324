import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import {
  ExclamationTriangleFill,
  GearFill,
  BoxArrowRight,
} from "react-bootstrap-icons";
import { useAuthContext } from "../../context/AuthContext";

import "./Header.css";
import { useBuscaPedidos } from "../../features/Pedidos/hooks/useBuscaPedidos";

const Header = () => {
  const { estabelecimento, logout, isLoggedIn } = useAuthContext();
  const { pedidosConfirmar, buscaPedidos } = useBuscaPedidos(estabelecimento);
  const location = useLocation();
  const navigate = useNavigate();
  const handlePedidosClick = () => {
    if (location.pathname === "/pedidos") {
      window.location.reload();
    } else {
      navigate("/pedidos");
    }
  };

  return (
    <Navbar
      bg="light"
      expand="lg"
      className="navbar-fixed-top px-5 py-3 text-dark"
    >
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Brand href="/" className="text-dark fs-5 mx-auto">
        <b>{estabelecimento.razao_social || "Estabelecimento"}</b>
      </Navbar.Brand>

      <Navbar.Collapse id="basic-navbar-nav" className="mx-lg-auto">
        <Nav className="ms-lg-4 text-center">
          {isLoggedIn && (
            <>
              <Nav.Link
                as={Link}
                to="/produto"
                className="text-black navbar-btn"
              >
                Produtos
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/taxa_entrega-list"
                className="text-black navbar-btn"
              >
                Taxas
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/relatorios"
                className="text-black navbar-btn"
              >
                Relatórios
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/pedidos" 
                className="text-black navbar-btn"
                onClick={handlePedidosClick}
              >
                {pedidosConfirmar ? (
                  <div style={{ color: "orange" }}>
                    <ExclamationTriangleFill
                      size={16}
                      className="me-2"
                      aria-label="Pedidos pendentes"
                    />
                    <b>Pedidos</b>
                  </div>
                ) : (
                  "Pedidos"
                )}
              </Nav.Link>
            </>
          )}
        </Nav>
        <Nav className="ms-lg-auto text-center">
          <Nav.Link as={Link} to="/Config" className="text-black navbar-btn">
            <GearFill aria-label="Configurações" />
            <span className="text-center">Config</span>
          </Nav.Link>

          {isLoggedIn && (
            <Nav.Link onClick={logout} className="cursor-pointer navbar-btn">
              <BoxArrowRight aria-label="Sair" />
              <span>Sair</span>
            </Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
