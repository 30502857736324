import React, { useState, useEffect, useRef } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "./Relatorios.css";
import search from "../../assets/icons/no-results.png";
import PedidoRelatorio from "./PedidoRelatorio";
import { toast } from "react-hot-toast";
import ModalRelatorios from "react-modal";
import ModalRelatorio from "./ModalRelatorio";
import * as PrestAPI from "../../service/api/PrestAPI";
import { useAuthContext } from "../../context/AuthContext";

const customStyles = {
  content: {
    transition: "0.2s",
    inset: "0px 0px 0px 45%",
    borderRadius: "5px 0px 0px 5px",
  },
  overlay: {
    zIndex: 2,
    position: "fixed",
    backgroundColor: "rgba(0.30, 0.20, 0, 0.20)",
  },
};

function Relatorios() {
  const { estabelecimento } = useAuthContext();
  const printRef = useRef();

  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [relatorioPedidos, setRelatorioPedidos] = useState([]);
  const [filteredPedidos, setFilteredPedidos] = useState([]);
  const [filters, setFilters] = useState({
    id: "",
    status: "",
    forma: "",
    inicio: "",
    fim: "",
    ordem: "Menor - Maior",
  });
  const [mediaMetrics, setMediaMetrics] = useState({
    mediaPedidos: 0,
    mediaValor: 0.0,
    mediaPedCheck: true,
    mediaVrCheck: true,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalPedido, setModalPedido] = useState({ pedido: {}, itens: [], subtotal: 0 });
  useEffect(() => {
    const fetchRelatorio = async () => {
      setLoading(true);
      try {
        const data = await PrestAPI.get(
          "relatorio",
          `estab=${estabelecimento.id_estabelecimento}`
        );
        setRelatorioPedidos(data);
        setFilteredPedidos(data);
        calcularMedias(data);
      } catch (error) {
        toast.error("Erro ao carregar relatórios");
      } finally {
        setLoading(false);
      }
    };
    fetchRelatorio();
  }, []);

  const showModal = async (pedido) => {
    try {
      const value = await PrestAPI.get(
        "Pedidos",
        `id_pedido=${pedido.id_pedido}`
      );
      setModalPedido({ pedido, itens: value, subtotal: calculateSubtotal(value) });
    } catch (error) {
      toast.error("Erro ao abrir o pedido");
    }
    setIsModalVisible(true);
  };

  const hideModal = () => setIsModalVisible(false);

  const calculateSubtotal = (itens) => {
    return itens.reduce((soma, item) => {
      const precoSubItens = item.vr_total_sub_itens || 0;
      return soma + (item.vr_unitario + precoSubItens) * item.quantidade;
    }, 0);
  };

  const setFiltro = () => {
    setLoading(true);
    const { id, status, forma, inicio, fim, ordem } = filters;

    if ((inicio && !fim) || (!inicio && fim)) {
      toast.error("Informe ambas as datas ou nenhuma.");
      setLoading(false);
      return;
    }
    if (inicio && fim && new Date(inicio) > new Date(fim)) {
      toast.error("A data inicial deve ser menor que a final.");
      setLoading(false);
      return;
    }

    let pedidosFiltrados = relatorioPedidos.filter(
      (pedido) =>
        filtroId(pedido, id) &&
        filtroStatus(pedido, status) &&
        filtroForma(pedido, forma) &&
        filtroData(pedido, inicio, fim)
    );

    pedidosFiltrados.sort((a, b) =>
      ordem === "Menor - Maior"
        ? new Date(a.data_hora) - new Date(b.data_hora)
        : new Date(b.data_hora) - new Date(a.data_hora)
    );

    setFilteredPedidos(pedidosFiltrados);
    calcularMedias(pedidosFiltrados);
    setLoading(false);
  };

  const filtroId = (pedido, id) => (id ? pedido.id_pedido === parseInt(id) : true);
  const filtroStatus = (pedido, status) =>
    status && status !== "Todos" ? pedido.status_pedido === status.toUpperCase() : true;
  const filtroForma = (pedido, forma) =>
    forma ? pedido.forma_pagamento.toLowerCase().includes(forma.toLowerCase().trim()) : true;
  const filtroData = (pedido, inicio, fim) => {
    if (inicio && fim) {
      return (
        new Date(pedido.data_hora) >= new Date(`${inicio} 00:00:00`) &&
        new Date(pedido.data_hora) <= new Date(`${fim} 23:59:59`)
      );
    }
    return true;
  };


  const calcularMedias = (pedidos) => {
    if (pedidos.length === 0) {
      setMediaMetrics((prev) => ({ ...prev, mediaPedidos: 0, mediaValor: 0.0 }));
      return;
    }

    const totalPedidosPorDia = {};
    pedidos.forEach((pedido) => {
      const data = new Date(pedido.data_hora).toLocaleDateString('pt-BR');
      if (!totalPedidosPorDia[data]) {
        totalPedidosPorDia[data] = 0;
      }
      totalPedidosPorDia[data]++;
    });

    const totalDias = Object.keys(totalPedidosPorDia).length;
    const mediaPedidos = totalDias > 0 ? Math.trunc(pedidos.length / totalDias) : 0;

    const totalValor = pedidos.reduce((acc, pedido) => acc + (pedido.vr_pedido || 0), 0);
    const mediaValor = Number((totalValor / pedidos.length).toFixed(2));

    setMediaMetrics((prev) => ({
      ...prev,
      mediaPedidos: prev.mediaPedCheck ? mediaPedidos : prev.mediaPedidos,
      mediaValor: prev.mediaVrCheck ? mediaValor : prev.mediaValor,
    }));
  };


  const limparFiltro = () => {
    setFilters({ id: "", status: "", forma: "", inicio: "", fim: "", ordem: "Menor - Maior" });
    setFilteredPedidos([]);
  };

  const imprimirRelatorio = () => {
    const printContent = printRef.current;
    if (!printContent) {
        toast.error("Nada para imprimir!");
        return;
    }

    const printWindow = window.open("", "_blank");
    if (!printWindow) {
        toast.error("Não foi possível abrir a janela de impressão. Verifique se os pop-ups estão bloqueados.");
        return;
    }

    printWindow.document.write(`
      <html>
        <head>
          <title>Relatório de Pedidos</title>
          <style>
            /* Estilos de impressão personalizados */
            body {
              font-family: Arial, sans-serif;
              padding: 20px;
              color: #000;
            }
            h2 { text-align: center; margin-bottom: 20px; }
            .header, .footer { width: 100%; text-align: center; font-size: 12px; margin-bottom: 20px; }
            .card { border: 1px solid #ccc; border-radius: 5px; margin-bottom: 15px; padding: 15px; background-color: #f8f8f8; }
            .row { display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px; }
            .col-2 { width: 16%; text-align: center; padding: 5px; box-sizing: border-box; }
            .rounded-2 { border-radius: 4px; padding: 4px; text-align: center; font-weight: bold; }
            .status-confirmado { border: 1px solid DeepSkyBlue; background-color: #e0f7fa; }
            .status-enviado { border: 1px solid gray; background-color: #f0f0f0; }
            .status-entregue { border: 1px solid green; background-color: #abfc8b; }
            .status-cancelado { border: 1px solid #8c2626; background-color: #fc9797; }
            .status-pendente { border: 1px solid orange; background-color: #fcee9f; }
            .page-break { page-break-after: always; }
            @media print { body { margin: 0; } .card { page-break-inside: avoid; } }
          </style>
        </head>
        <body>
          <div class="header">
            <h2>Relatório de Pedidos</h2>
            <p>Data da Geração: ${new Date().toLocaleDateString('pt-BR')}</p>
          </div>
          <div class="table-header">
            <div class="row">
              <div class="col-2 text-center"><b>Id:</b></div>
              <div class="col-2 text-center"><b>Cliente:</b></div>
              <div class="col-2 text-center"><b>Status:</b></div>
              <div class="col-2 text-center"><b>Data:</b></div>
              <div class="col-2 text-center"><b>Forma:</b></div>
              <div class="col-2 text-center"><b>Total:</b></div>
            </div>
             ${printContent.innerHTML}
          </div>
          <!-- Conteúdo Adicional ao Final da Página -->
          <div class="summary">
            <div class="col-12">
              <hr class="mb-0" />
            </div>
            <div class="col-12">
              <div class="col-12 row" style="margin-top: 15px;">
                <div class="col-3 text-center">
                  ${mediaMetrics.mediaPedCheck ? `
                    <div class="col-12">
                      <h4>
                        Média - pedidos diários:
                        <div class="w-100 text-center"></div>
                        <b>${mediaMetrics.mediaPedidos}</b>
                      </h4>
                    </div>
                  ` : ''}
                </div>
                <div class="col-3 text-center">
                  ${mediaMetrics.mediaVrCheck ? `
                    <div class="col-12">
                      <h4>
                        Ticket Médio por Venda
                        <div class="w-100 text-center"></div>
                        <b>${new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(mediaMetrics.mediaValor)}</b>
                      </h4>
                    </div>
                  ` : ''}
                </div>
                <div class="col-3 text-center">
                  <div class="col-12">
                    <h4>
                      Pedidos encontrados:
                      <div class="w-100 text-center"></div>
                      <b>${filteredPedidos.length}</b>
                    </h4>
                  </div>
                </div>
                <div class="col-3 text-center">
                  <div class="col-12">
                    <h4>
                      Valor total:
                      <div class="w-100 text-center" style="margin-top: 10px;"></div>
                      <b>${new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(filteredPedidos.reduce((acc, pedido) => acc + (pedido.vr_pedido || 0), 0))}</b>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Rodapé -->
          <div class="footer">
            <p>Este é um relatório gerado automaticamente.</p>
          </div>
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.onload = () => {
        printWindow.print();
        printWindow.close();
    };
};

  return (
    <>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList className="container">
          <Tab>Pedido</Tab>
        </TabList>
        <TabPanel>
          <div className="container-fluid">
            <div className="row g-0">
              <div
                className="col-3 overflow-auto px-4"
                style={{
                  backgroundColor: "#F8F8F8",
                  border: "solid #EAEAEA 1px",
                  borderRight: "none",
                  borderRadius: "5px 0px 0px 5px",
                  height: "80vh",
                }}
              >
                <div className="col-12 pt-2">
                  <h4 style={{ fontWeight: "bolder" }}>Filtrar por:</h4>
                  <hr />
                </div>
                <div className="col-12">
                  <label className="form-label">Id do Pedido:</label>

                  <input
                    className="form-control input-md"
                    type="number"
                    id="idRela"
                    value={filters.id}
                    onChange={(e) =>
                      setFilters({ ...filters, id: e.target.value })
                    }
                  />
                </div>

                <div className="col-12 mb-2">
                  <label className="form-label">Status do Pedido:</label>
                  <select
                    className="form-select"
                    id="statusRela"
                    value={filters.status}
                    onChange={(e) =>
                      setFilters({ ...filters, status: e.target.value })
                    }
                  >
                    <option value="Todos">Todos</option>
                    <option value="Pendente">Pendente</option>
                    <option value="Confirmado">Confirmado</option>
                    <option value="Despachado">Despachado</option>
                    <option value="Entregue">Entregue</option>
                    <option value="Cancelado">Cancelado</option>
                  </select>
                </div>
                <div className="col-12 mb-2">
                  <label className="form-label">Forma de pagamento:</label>
                  <select
                    className="form-select"
                    id="formaRela"
                    value={filters.forma}
                    onChange={(e) =>
                      setFilters({ ...filters, forma: e.target.value })
                    }
                  >
                    <option value="">Todos</option>
                    <option value="Dinheiro">Dinheiro</option>
                    <option value="Pix">PIX</option>
                    <option value="Cartao">Cartão - Crédito</option>
                  </select>
                </div>
                <div className="col-12">
                  <label className="form-label">De:</label>
                  <input
                    type="date"
                    name="de"
                    id="inicioRela"
                    className="form-control input-md"
                    value={filters.inicio}
                    onChange={(e) =>
                      setFilters({ ...filters, inicio: e.target.value })
                    }
                  />
                </div>

                <div className="col-12">
                  <label className="form-label">Até:</label>
                  <input
                    type="date"
                    name="ate"
                    id="fimRela"
                    value={filters.fim}
                    className="form-control input-md"
                    onChange={(e) =>
                      setFilters({ ...filters, fim: e.target.value })
                    }
                  />
                </div>

                <div className="col-12 mb-2">
                  <label className="form-label">Ordenar (data):</label>
                  <select
                    className="form-select"
                    onChange={(e) =>
                      setFilters({ ...filters, ordem: e.target.value })
                    }
                    value={filters.ordem}
                  >
                    <option value="">Padrão</option>
                    <option value="Menor - Maior">Menor - Maior</option>
                    <option value="Maior - Menor">Maior - Menor</option>
                  </select>
                </div>
                {/* ----------- Checkboxes ---------- */}

                <div className="col-12 form-check mb-2">
                  <label className="form-check-label">
                    Média - pedidos diários
                  </label>
                  <input
                    type="checkbox"
                    id="qtde_media"
                    className="form-check-input"
                    style={{ width: "20px", height: "20px" }}
                    checked={mediaMetrics.mediaPedCheck}
                    onChange={() =>
                      setMediaMetrics((prev) => ({
                        ...prev,
                        mediaPedCheck: !prev.mediaPedCheck,
                      }))
                    }
                  />
                </div>
                <div className="col-12 form-check mb-2">
                  <label className="form-check-label">
                    Ticket Médio - diário
                  </label>
                  <input
                    type="checkbox"
                    id="valor_media"
                    className="form-check-input"
                    style={{ width: "20px", height: "20px" }}
                    checked={mediaMetrics.mediaVrCheck}
                    onChange={() =>
                      setMediaMetrics((prev) => ({
                        ...prev,
                        mediaVrCheck: !prev.mediaVrCheck,
                      }))
                    }
                  />
                </div>
                <div className="col-12">
                  <button
                    className="btn btn-md btn-vk w-100 mt-2"
                    onClick={() => {
                      setFiltro();
                    }}
                  >
                    Filtrar Pedidos
                  </button>
                  <button
                    className="btn btn-outline-vk w-100 mt-2"
                    onClick={() => {
                      limparFiltro();
                    }}
                  >
                    Limpar Filtros
                  </button>
                  <button
                    className="btn btn-md btn-vk w-100 mt-2"
                    onClick={() => {
                      imprimirRelatorio();
                    }}
                  >
                    Imprimir
                  </button>
                </div>
              </div>
              <div className="col-9">
                <div
                  className="col-12"
                  style={{
                    border: "solid #EAEAEA 1px",
                    borderLeft: "none",
                    borderRadius: "0px 5px 0px 0px",
                    height: "7vh",
                    backgroundColor: "#f8f8f8",
                  }}
                >
                  {filteredPedidos.length > 0 && (
                    <nav
                      className="m-0"
                      style={{
                        margin: "0px",
                        padding: "20px 115px 20px 100px",
                      }}
                    >
                      <div className="col-12">
                        <div className="row">
                          <div className="col-2">
                            <div className="col-12 text-center">
                              <b>Id:</b>
                            </div>
                          </div>
                          <div
                            className="col-2"
                            style={{
                              borderLeft: "solid 1.5px #000",
                            }}
                          >
                            <div className="col-12 text-center">
                              <b>Cliente:</b>
                            </div>
                          </div>
                          <div
                            className="col-2 text-center"
                            style={{
                              borderLeft: "solid 1.5px #000",
                            }}
                          >
                            <div className="col-12 text-center">
                              <b>Status:</b>
                            </div>
                          </div>
                          <div
                            className="col-2 text-center"
                            style={{
                              borderLeft: "solid 1.5px #000",
                            }}
                          >
                            <div className="col-12 text-center">
                              <b>Data:</b>
                            </div>
                          </div>
                          <div
                            className="col-2"
                            style={{
                              borderLeft: "solid 1.5px #000",
                            }}
                          >
                            <div className="col-12 text-center">
                              <b>Forma:</b>
                            </div>
                          </div>
                          <div
                            className="col-2"
                            style={{
                              borderLeft: "solid 1.5px #000",
                            }}
                          >
                            <div className="col-12 text-center">
                              <b>Total:</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </nav>
                  )}
                </div>
                <div
                  className="col-12"
                  style={{
                    border: "solid #EAEAEA 1px",
                    borderTop: "none",
                    borderRadius: "0px 0px 5px 0px",
                    height: "73vh",
                    padding: "0px",
                  }}
                >
                  {!loading ? (
                    <>
                      <div
                        className="col-12 overflow-auto"
                        style={{
                          height: "60vh",
                          paddingTop: "30px",
                        }}
                      >
                        <div className="col-12 py-2 px-5 row" ref={printRef}>
                          {filteredPedidos.length > 0 ? (
                            filteredPedidos.map((pedido) => (
                              <PedidoRelatorio
                                key={pedido.id_pedido}
                                pedido={pedido}
                                hideModal={() => hideModal()}
                                showModal={() => showModal(pedido)}
                              />
                            ))
                          ) : (
                            <div
                              className="col-12 row justify-content-center w-100 h-100"
                              style={{
                                paddingTop: "20%",
                              }}
                            >
                              <div
                                className="col text-center"
                                style={{
                                  color: "#ff5722",
                                  fontWeight: "bold",
                                }}
                              >
                                <div className="col-12">
                                  <img
                                    src={search}
                                    alt=" "
                                    width="150px"
                                    height="150px"
                                    style={{
                                      margin: "0 auto !important",
                                    }}
                                  />
                                </div>
                                <div className="col-12 text-center">
                                  <h4>
                                    <b>Nenhum pedido encontrado.</b>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {filteredPedidos.length !== 0 && (
                        <>
                          <div className="col-12">
                            <hr className="mb-0" />
                          </div>
                          <div className="col-12">
                            <div
                              className="col-12 row"
                              style={{ marginTop: "15px" }}
                            >
                              <div className="col-3 text-center">
                                {mediaMetrics.mediaPedCheck && (
                                  <div className="col-12">
                                    <h4>
                                      Média - pedidos diários:
                                      <div className="w-100"></div>
                                      <b>{mediaMetrics.mediaPedidos}</b>
                                    </h4>
                                  </div>
                                )}
                              </div>
                              <div className="col-3 text-center">
                                {mediaMetrics.mediaVrCheck && (
                                  <div className="col-12">
                                    <h4>
                                      Ticket Médio por Venda
                                      <div className="w-100"></div>
                                      <b>
                                        {new Intl.NumberFormat("pt-BR", {
                                          style: "currency",
                                          currency: "BRL",
                                        }).format(mediaMetrics.mediaValor)}
                                      </b>
                                    </h4>
                                  </div>
                                )}
                              </div>
                              <div className="col-3 text-center">
                                <div className="col-12">
                                  <h4>
                                    Pedidos encontrados:
                                    <div className="w-100"></div>
                                    <b>{filteredPedidos.length}</b>
                                  </h4>
                                </div>
                              </div>
                              <div className="col-3 text-center">
                                <div className="col-12">
                                  <h4>
                                    Valor total:
                                    <div
                                      className="w-100"
                                      style={{ marginTop: "10px" }}
                                    ></div>
                                    <b>
                                      {new Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                      }).format(
                                        filteredPedidos.reduce(
                                          (acc, pedido) => acc + (pedido.vr_pedido || 0),
                                          0
                                        )
                                      )}
                                    </b>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <div className="loaderRela" id="loader">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
      <ModalRelatorios
        ariaHideApp={false}
        isOpen={isModalVisible}
        style={customStyles}
        contentLabel="Relatório Pedido"
        onRequestClose={hideModal}
      >
        <ModalRelatorio pedido={modalPedido.pedido} itens={modalPedido.itens} subtotal={modalPedido.subtotal} />
      </ModalRelatorios>
    </>
  );
}

export default Relatorios;
