import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { useGetBanners } from "../../features/banner/hooks/useGetBanner";
import { useUpdateBanner } from "../../features/banner/hooks/useUpdateBanner";
import { useAddBanner } from "../../features/banner/hooks/useAddBanner";
import useUploadImage from "../../features/imagens/useUploadImage";
import { toast } from "react-hot-toast";
import { useDeleteBanner } from "../../features/banner/hooks/useDeleteBanner";
import { useAuthContext } from "../../context/AuthContext";

export default function FormSlide({ tipoLoja }) {
  const [desktopImages, setDesktopImages] = useState([]);
  const [mobileImages, setMobileImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeTab, setActiveTab] = useState("desktop");
  const { estabelecimento } = useAuthContext();
  const { uploadImage, isLoadingImage } = useUploadImage();
  const { addBanner, loading: loadingAdd } = useAddBanner(estabelecimento.uid);
  const { updateBanner, loading: loadingUpdate } = useUpdateBanner(
    estabelecimento.uid
  );
  const { banners: imageBanner, refetch: refetchBanners } = useGetBanners(
    estabelecimento.uid
  );
  const { deleteBanner } = useDeleteBanner(estabelecimento.uid);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const defaultImageObject = {
      link_imagem: "",
      preview: "",
      bannerId: null,
    };

    const initializeImages = () => {
      if (Array.isArray(imageBanner) && imageBanner.length > 0) {
        const desktopImages = imageBanner
          .filter((img) => img.tipo_slide === verificaTipoSlide("desktop"))
          .map((img) => ({
            link_imagem: img.link_imagem,
            preview: img.link_imagem,
            bannerId: img.id,
          }));

        const mobileImages = imageBanner
          .filter((img) => img.tipo_slide === verificaTipoSlide("mobile"))
          .map((img) => ({
            link_imagem: img.link_imagem,
            preview: img.link_imagem,
            bannerId: img.id,
          }));

        // Garantindo que cada lista tenha pelo menos 3 itens
        while (desktopImages.length < 3) {
          desktopImages.push({ ...defaultImageObject });
        }

        while (mobileImages.length < 3) {
          mobileImages.push({ ...defaultImageObject });
        }

        setDesktopImages(desktopImages);
        setMobileImages(mobileImages);
      } else {
        setDesktopImages(new Array(3).fill(defaultImageObject));
        setMobileImages(new Array(3).fill(defaultImageObject));
      }
    };

    initializeImages();
  }, [imageBanner]);

  useEffect(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }, [desktopImages, mobileImages]);

  const validateImage = (file, type) => {
    return new Promise((resolve, reject) => {
      if (!file) {
        reject("Nenhuma imagem selecionada");
      }
      if (type === "desktop") {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          const { width, height } = img;
          const aspectRatio = width / height;
          const expectedAspectRatio = 1900 / 500;
          const margin = 0.05;

          if (
            aspectRatio < expectedAspectRatio * (1 - margin) ||
            aspectRatio > expectedAspectRatio * (1 + margin)
          ) {
            reject(
              "A imagem deve ter uma proporção aproximada de 1900x500 (3.8:1)"
            );
          } else {
            resolve();
          }

          URL.revokeObjectURL(img.src);
        };
        img.onerror = () => {
          reject("Erro ao carregar a imagem");
        };
      } else {
        resolve();
      }
    });
  };

  const handleImageChange = (e, type) => {
    const file = e.target.files[0];
    if (!file) {
      toast.error("Nenhuma imagem selecionada");
      return;
    }

    const imagesArray = type === "desktop" ? desktopImages : mobileImages;

    if (imagesArray.filter((img) => img.preview).length >= 3) {
      toast.error(`Somente 3 imagens de ${type} são permitidas`);
      return;
    }

    validateImage(file, type)
      .then(() => {
        updateImageState(file, type);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const updateImageState = (file, type) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (type === "desktop") {
        setDesktopImages((prev) =>
          prev.map((img, i) =>
            i === currentIndex
              ? {
                  ...img,
                  link_imagem: file,
                  preview: reader.result,
                }
              : img
          )
        );
      } else {
        setMobileImages((prev) =>
          prev.map((img, i) =>
            i === currentIndex
              ? {
                  ...img,
                  link_imagem: file,
                  preview: reader.result,
                }
              : img
          )
        );
      }
    };
    reader.readAsDataURL(file);
  };

  const handleSave = async () => {
    try {
      const imagesArray =
        activeTab === "desktop" ? desktopImages : mobileImages;
      const { link_imagem, bannerId } = imagesArray[currentIndex] || {};

      if (!link_imagem) {
        toast.error(
          `Por favor, adicione uma imagem de ${activeTab} antes de salvar`
        );
        return;
      }

      let imageUrl = "";

      if (link_imagem instanceof File) {
        imageUrl = await uploadImage(link_imagem);
      } else {
        imageUrl = link_imagem;
      }

      if (!imageUrl) {
        toast.error("Erro ao fazer upload da imagem, tente novamente.");
        return;
      }

      const tipoSlide = verificaTipoSlide(activeTab);
      if (tipoSlide === null) {
        toast.error("Tipo de slide inválido");
        return;
      }

      const bannerData = {
        tipo_slide: tipoSlide,
        link_imagem: imageUrl,
      };

      if (bannerId) {
        await updateBanner({
          id: bannerId,
          ...bannerData,
        });
      } else {
        await addBanner(bannerData);
      }

      await refetchBanners();
      toast.success("Imagem salva com sucesso!");
    } catch (error) {
      console.error("Erro ao salvar imagem:", error);
      toast.error("Erro ao salvar imagem");
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Tem certeza de que deseja excluir esta imagem?")) {
      const imagesArray =
        activeTab === "desktop" ? desktopImages : mobileImages;

      if (currentIndex >= 0 && currentIndex < imagesArray.length) {
        const { bannerId } = imagesArray[currentIndex];
        if (bannerId) {
          try {
            await deleteBanner(bannerId);
            toast.success("Imagem excluída com sucesso!");
            await refetchBanners();
          } catch (error) {
            console.error("Erro ao excluir imagem:", error);
            toast.error("Erro ao excluir imagem");
          }
        }

        if (activeTab === "desktop") {
          setDesktopImages((prev) =>
            prev.map((img, i) =>
              i === currentIndex
                ? {
                    ...img,
                    link_imagem: "",
                    preview: "",
                    bannerId: null,
                  }
                : img
            )
          );
        } else {
          setMobileImages((prev) =>
            prev.map((img, i) =>
              i === currentIndex
                ? {
                    ...img,
                    link_imagem: "",
                    preview: "",
                    bannerId: null,
                  }
                : img
            )
          );
        }
      }
    }
  };

  const verificaTipoSlide = (tab) => {
    if (tab === "desktop" && tipoLoja === "loja") return 2;
    if (tab === "mobile" && tipoLoja === "loja") return 4;
    if (tab === "desktop" && tipoLoja === "multiloja") return 1;
    if (tab === "mobile" && tipoLoja === "multiloja") return 3;
    return null;
  };

  const goToPreviousSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0
        ? activeTab === "desktop"
          ? desktopImages.length - 1
          : mobileImages.length - 1
        : prevIndex - 1
    );
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex ===
      (activeTab === "desktop"
        ? desktopImages.length - 1
        : mobileImages.length - 1)
        ? 0
        : prevIndex + 1
    );
  };

  return (
    <div className="my-4">
      {/* Navegação por Tabs */}
      <ul className="nav nav-tabs mb-3" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === "desktop" ? "active" : ""}`}
            type="button"
            onClick={() => setActiveTab("desktop")}
          >
            Desktop
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === "mobile" ? "active" : ""}`}
            type="button"
            onClick={() => setActiveTab("mobile")}
          >
            Mobile
          </button>
        </li>
      </ul>

      {/* Input e Ações */}
      <div className="d-flex flex-column align-items-center my-4">
        <div className="col-lg-6">
          <input
            type="file"
            className="form-control mt-2"
            accept="image/*"
            ref={fileInputRef}
            onChange={(e) => handleImageChange(e, activeTab)}
          />
        </div>
        <div className="mt-2 mx-auto row col-lg-3">
          <button
            className="btn btn-vk"
            onClick={handleSave}
            disabled={isLoadingImage || loadingAdd || loadingUpdate}
          >
            {isLoadingImage || loadingAdd || loadingUpdate
              ? "Salvando..."
              : "Salvar Imagem"}
          </button>
          {activeTab === "desktop"
            ? desktopImages.length > 0 &&
              desktopImages[currentIndex].link_imagem && (
                <button
                  className="btn btn-secondary mt-2"
                  onClick={handleDelete}
                  disabled={isLoadingImage || loadingAdd || loadingUpdate}
                >
                  Excluir Imagem
                </button>
              )
            : mobileImages.length > 0 &&
              mobileImages[currentIndex].link_imagem && (
                <button
                  className="btn btn-secondary mt-2"
                  onClick={handleDelete}
                  disabled={isLoadingImage || loadingAdd || loadingUpdate}
                >
                  Excluir Imagem
                </button>
              )}
        </div>
      </div>

      {/* Carousel */}
      <div
        id={`carouselExample-${tipoLoja}`}
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          {(activeTab === "desktop" ? desktopImages : mobileImages).map(
            (img, index) => (
              <div
                className={`carousel-item ${
                  index === currentIndex ? "active" : ""
                }`}
                key={index}
              >
                <div className="d-flex flex-column align-items-center my-4">
                  <h5>Slide {index + 1}</h5>
                  {activeTab === "desktop" && (
                    <div className="d-flex flex-column align-items-center">
                      <h5>Desktop Preview</h5>
                      <p>
                        A imagem deve ter uma proporção aproximada de 1900x500
                        (3.8:1)
                      </p>
                    </div>
                  )}
                  {activeTab === "mobile" && img.preview ? (
                    <div
                      style={{
                        width: "300px",
                        height: "600px",
                        border: "16px solid black",
                        borderRadius: "36px",
                        padding: "16px",
                        boxSizing: "border-box",
                        backgroundColor: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={img.preview}
                        className="img-fluid rounded-4"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "cover",
                        }}
                        alt={`Mobile Slide ${index + 1}`}
                      />
                    </div>
                  ) : (
                    img.preview && (
                      <img
                        src={img.preview}
                        className="img-fluid rounded-4"
                        style={{ maxHeight: "300px", objectFit: "cover" }}
                        alt={`Slide ${index + 1}`}
                      />
                    )
                  )}
                  {!img.preview && (
                    <span>Nenhuma imagem disponível para este slide</span>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>

      {/* Botões de Navegação do Carousel */}
      <div className="d-flex justify-content-between mt-4">
        <button
          className="btn btn-secondary"
          onClick={goToPreviousSlide}
          disabled={
            (activeTab === "desktop" ? desktopImages : mobileImages).length <= 1
          }
        >
          Anterior
        </button>
        <button
          className="btn btn-secondary"
          onClick={goToNextSlide}
          disabled={
            (activeTab === "desktop" ? desktopImages : mobileImages).length <= 1
          }
        >
          Próximo
        </button>
      </div>
    </div>
  );
}
