import React from "react";
import { formatCurrencyBRL } from "../../utils/formatters";
import { X, Check2 } from "react-bootstrap-icons";

import { useAuthContext } from "../../context/AuthContext";

const statusMap = {
  PENDENTE: "CONFIRMADO",
  CONFIRMADO: "ENVIADO",
  ENVIADO: "ENTREGUE",
};

const PedidoSelecionado = ({
  pedido,
  subtotal,
  itens,
  documentoHide,
  renderStatus,
  updateStatusPedido,
}) => {
  const { estabelecimento } = useAuthContext();

  const formatAddressLine = (...parts) => {
    return parts.filter(Boolean).join("");
  };

  function renderAddress(address) {
    return (
      <>
        {(address.logradouro || address.numero) && (
          <div style={{ fontSize: "16px" }}>
            {formatAddressLine(
              address.logradouro,
              address.logradouro && address.numero ? ", " : "",
              address.numero
            )}
          </div>
        )}
        {(address.bairro || address.municipio || address.uf) && (
          <div style={{ fontSize: "16px" }}>
            {formatAddressLine(
              address.bairro,
              address.bairro && address.municipio ? " - " : "",
              address.municipio,
              (address.bairro || address.municipio) && address.uf ? ", " : "",
              address.uf
            )}
          </div>
        )}
        {address.complemento && (
          <div style={{ fontSize: "16px" }}>{address.complemento}</div>
        )}
      </>
    );
  }

  return (
    <>
      <div className="row">
        <div
          className="col-sm-3"
          style={{
            WebkitUserSelect: "none",
            MozUserSelect: "none",
            MsUserSelect: "none",
            userSelect: "none",
          }}
        >
          {renderStatus(pedido.status_pedido)}
        </div>
        <div className="col-6" style={{ textAlign: "center" }}>
          <h3>
            <b>{`Pedido ${pedido.id_pedido}`}</b>
          </h3>
          {pedido.tipo_pedido === "RETIRADA" ? (
            <h4 style={{ color: "red" }}>Pedido para retirada</h4>
          ) : null}
        </div>
        <div className="col-3">
          <span style={{ fontSize: "15px" }}>
            {new Date(pedido.data_hora).toLocaleString("pt-BR", {
              timeZone: "GMT",
            })}
          </span>
        </div>
      </div>
      {pedido.id_pedido && (
        <div className="row">
          {pedido.data_hora_agendamento && (
            <div className="col-md-12" style={{ textAlign: "left" }}>
              <b className="fs-5">Data de encomenda: </b>
              <div style={{ fontSize: "16px" }}>
                {new Date(pedido.data_hora_agendamento).toLocaleString(
                  "pt-BR",
                  {
                    timeZone: "GMT",
                  }
                )}
              </div>
            </div>
          )}
          <div
            className={pedido.telefone ? "col-sm-9" : "col-sm-12"}
            style={{ textAlign: "left" }}
          >
            <h5 className="m-0">
              <b>Cliente:</b>
              <div style={{ fontSize: "16px" }}>
                {` ${pedido.nome_cliente}`}
              </div>
            </h5>
          </div>
          {pedido.telefone && (
            <div className="col-sm-3 text-lef">
              <b>Telefone:</b>
              <div style={{ fontSize: "16px" }}>{pedido.telefone}</div>
            </div>
          )}
          <div
            className={pedido.documento ? "col-sm-9" : "col-sm-12"}
            style={{ textAlign: "left" }}
          >
            <h5>
              <b>Endereço:</b>
              {pedido.tipo_pedido === "retirada"
                ? renderAddress({
                    logradouro: estabelecimento.logradouro,
                    numero: estabelecimento.numero,
                    bairro: estabelecimento.bairro,
                    municipio: estabelecimento.municipio,
                    uf: estabelecimento.uf,
                    complemento: estabelecimento.complemento,
                  })
                : renderAddress({
                    logradouro: pedido.entrega_logradouro,
                    numero: pedido.entrega_numero,
                    bairro: pedido.entrega_bairro,
                    municipio: pedido.entrega_municipio,
                    uf: pedido.entrega_uf,
                    complemento: pedido.entrega_complemento,
                  })}
            </h5>
          </div>

          {pedido.documento && (
            <>
              <div className="col-sm-3 text-left">
                <h5 className="mb-0 mt-1">
                  <b>Documento:</b>
                  <div>{documentoHide}</div>
                </h5>
              </div>
            </>
          )}
          <div className="col-sm-12">
            <hr style={{ borderTop: "solid 1.5px", color: "lightgray" }} />
          </div>
          <div className="container-fluid col-sm-12">
            {itens.map((item) => (
              <div className="card" key={item.num_item}>
                <div className="card-body">
                  <div className="row">
                    <div
                      className="col"
                      style={{ fontSize: "15px" }}
                    >{`${item.quantidade}x ${item.nome_produto}`}</div>
                    <div className="col text-end">
                      {formatCurrencyBRL(item.vr_unitario)}
                    </div>
                    {item.subItens &&
                      item.subItens.map(
                        (complemento) =>
                          complemento && (
                            <div
                              key={complemento.id_complemento}
                              className="col-12 row"
                            >
                              <div
                                className="col text-body-secondary ps-4"
                                style={{ fontSize: "12px" }}
                              >
                                {` ${complemento.quantidade}x ${complemento.complemento}`}
                              </div>
                              <div
                                className="col text-body-secondary justify-content-end row"
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                {formatCurrencyBRL(complemento.vr_adicional)}
                              </div>
                            </div>
                          )
                      )}

                    <div
                      className="col-12 text-end"
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {item.vr_total_sub_iten
                        ? formatCurrencyBRL(
                            item.vr_unitario + item.vr_total_sub_itens
                          )
                        : formatCurrencyBRL(item.vr_total)}
                    </div>
                    <div className="row">
                      <span>
                        <strong>Observações:</strong> {item.observacao}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="row">
              <div className="col-md-6 ms-auto">
                <div className="row justity-content-between">
                  <div className="col" style={{ fontSize: "16px" }}>
                    <b>Subtotal:</b>
                  </div>
                  <div className="col text-end" style={{ fontSize: "16px" }}>
                    <b>{formatCurrencyBRL(subtotal)}</b>
                  </div>
                </div>
                <div className="row justity-content-between">
                  <div className="col">
                    <b>Taxa:</b>
                  </div>
                  <div className="col text-end">
                    <b>{formatCurrencyBRL(pedido.entrega_taxa)}</b>
                  </div>
                </div>
                <div className="row justity-content-between">
                  <div className="col" style={{ fontSize: "16px" }}>
                    <b>Total:</b>
                  </div>
                  <div className="col text-end" style={{ fontSize: "16px" }}>
                    <b>{formatCurrencyBRL(pedido.vr_pedido)}</b>
                  </div>
                </div>
                {pedido.vr_troco !== 0 && (
                  <div className="row justity-content-between">
                    <div
                      className="col"
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      <b>Troco para:</b>
                    </div>
                    <div className="col text-end" style={{ fontSize: "16px" }}>
                      <b>{formatCurrencyBRL(pedido.vr_troco)}</b>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className=" w-100">
              <div className="col-12">
                <b>Forma de Pagamento:</b>
              </div>
              <div className="col-12">{pedido.forma_pagamento}</div>
              {pedido.entrega_observacao ? (
                <>
                  <div
                    className="col-sm-12 text-left"
                    style={{ marginTop: "36px" }}
                  >
                    <h5 style={{ marginTop: "10px", marginBottom: "0px" }}>
                      <b>Observações:</b>
                    </h5>
                  </div>
                  <div className="col-sm-12 text-left">
                    <div style={{ fontSize: "16px" }}>
                      {pedido.entrega_observacao}
                    </div>
                  </div>
                </>
              ) : null}
              <div>
                <span>
                  <strong className="fs-6">Tipo de Pedido:</strong>{" "}
                  {pedido.tipo_pedido}
                </span>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <hr style={{ borderTop: "solid 1.5px", color: "lightgray" }} />
          </div>
          <div className="w-100 row g-2">
            {/* Botão de Cancelar */}
            {pedido.status_pedido !== "CANCELADO" &&
              pedido.status_pedido !== "ENTREGUE" &&
              pedido.status_pedido !== "ENVIADO" && (
                <div className="col-sm-3">
                  <button
                    className="btn btn-outline-secondary w-100 d-flex align-items-center justify-content-center"
                    onClick={() => updateStatusPedido(pedido, "CANCELADO")}
                  >
                    <X size={20} className="me-2" />
                    <span>Cancelar</span>
                  </button>
                </div>
              )}

            {/* Botão de Confirmar/Despachar/Concluir */}
            {pedido.status_pedido !== "CANCELADO" &&
              pedido.status_pedido !== "ENTREGUE" && (
                <div className="col-sm-3">
                  <button
                    className="btn btn-vk w-100 d-flex align-items-center justify-content-center"
                    onClick={() => {
                      const novoStatus =
                        statusMap[pedido.status_pedido] || "ENTREGUE";
                      updateStatusPedido(pedido, novoStatus);
                    }}
                  >
                    <Check2 size={20} className="me-2" />
                    {pedido.status_pedido === "PENDENTE" && (
                      <span>Confirmar</span>
                    )}
                    {pedido.status_pedido === "CONFIRMADO" && (
                      <span>Despachar</span>
                    )}
                    {pedido.status_pedido === "ENVIADO" && (
                      <span>Concluir</span>
                    )}
                  </button>
                </div>
              )}
          </div>
        </div>
      )}
    </>
  );
};
export default PedidoSelecionado;
