import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const ColorSelect = ({ onColorSelect }) => {
  const colorOptions = [
    {
      a: "#FF5722",
      b: "#FE8862",
      c: "#FFBAA4",
      d: "#FFE6DE",
      e: "#FFE6DE",
    },
    {
      a: "#000000",
      b: "#464646",
      c: "#7B7B7B",
      d: "#D8D8D8",
      e: "#FFFFFF",
    },
    {
      a: "#750000",
      b: "#C12323",
      c: "#FF0000",
      d: "#8AF80D",
      e: "#C6E6AC",
    },
  ];

  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);

  useEffect(() => {
    notifyColorSelect();
  }, [selectedOptionIndex]);

  const notifyColorSelect = () => {
    if (onColorSelect) {
      onColorSelect(colorOptions[selectedOptionIndex]);
    }
  };

  const handleClick = (index) => {
    setSelectedOptionIndex(index);
  };

  return (
    <div className="containerColorPicker mt-3">
      <h4 className="text-center">Minha Loja</h4>
      {colorOptions.map((option, index) => (
        <div
          key={index}
          style={
            selectedOptionIndex === index
              ? styles.selectedSwatch
              : styles.swatch
          }
          onClick={() => handleClick(index)}
        >
          {Object.values(option).map((color, idx) => (
            <div key={idx} style={{ ...styles.color, background: color }} />
          ))}
        </div>
      ))}
    </div>
  );
};

const styles = {
  color: {
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    border: "1px solid #FF5722",
  },
  swatch: {
    padding: "5px",
    background: "#fff",
    display: "flex",
    cursor: "pointer",
    justifyContent: "space-around",
    margin: "5px 0",
  },
  selectedSwatch: {
    padding: "5px",
    background: "#FFE6DE",
    boxShadow: "0 0 0 2px rgba(0,0,0,.2)",
    display: "flex",
    cursor: "pointer",
    justifyContent: "space-around",
  },
};

ColorSelect.propTypes = {
  onColorSelect: PropTypes.func,
};

ColorSelect.defaultProps = {
  onColorSelect: null,
};

export default ColorSelect;
