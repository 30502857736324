import { useState } from 'react';
import {apiClient} from "../../../service/api/PrestAPI";

export const useDeleteBanner = (storeUid) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const deleteBanner = async (id) => {
    setLoading(true);
    try {
      await apiClient.delete(`/store/${storeUid}/banner/${id}`);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { deleteBanner, loading, error };
};
