import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-hot-toast";
import { useAuthContext } from "../../context/AuthContext";
import * as PrestAPI from "../../service/api/PrestAPI";
import { formatCNPJ, formatPhone } from "../../utils/formatters";
import HorariosAtendimento from "../../components/Horarios/Horarios";
import useUploadImage from "../../features/imagens/useUploadImage";
import InputMask from "react-input-mask";
import useSegmentos from "../../features/Establecimentos/hooks/useSegmentos";
import useUpdateCurrentEstabelecimento from "../../features/Establecimentos/hooks/useUpdateEstabelecimentoUsuario";

const formSchema = z.object({
  razao_social: z.string().nonempty("Razão social é obrigatória."),
  nome_fantasia: z.string().nonempty("Nome fantasia é obrigatória."),
  cnpj: z.string().nonempty("CNPJ é obrigatório."),
  email: z.string().email("Email inválido.").optional(),
  telefone: z.string(),
  email_contato: z.string().optional(),
  telefone_contato: z.string(),
  segmento: z.string().nonempty("Segmento é obrigatório."),
  bairro: z.string().nonempty("Bairro é obrigatório."),
  logradouro: z.string().nonempty("Logradouro é obrigatório."),
  complemento: z.string(),
  cep: z.string().nonempty("CEP é obrigatório."),
  numero: z.string().nonempty("Número é obrigatório."),
  municipio: z.string().nonempty("Cidade é obrigatória."),
  uf: z.string().nonempty("UF é obrigatória."),
  descricao: z.string(),
  horario: z.any(),
});

const FormCadastro = () => {
  const { estabelecimento, userData } = useAuthContext();
  const { categorias } = useSegmentos();
  const [cep, setCep] = useState("");
  const { updateEstabelecimento, isUpdating } =
    useUpdateCurrentEstabelecimento();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      razao_social: estabelecimento.razao_social || "",
      nome_fantasia: estabelecimento.nome_fantasia || "",
      cnpj: formatCNPJ(estabelecimento.cnpj || ""),
      email: estabelecimento.email || "",
      telefone: formatPhone(estabelecimento.telefone || ""),
      email_contato: estabelecimento.email_contato || "",
      telefone_contato: formatPhone(estabelecimento.telefone_contato || ""),
      segmento: estabelecimento.segmento || "",
      bairro: estabelecimento.bairro || "",
      logradouro: estabelecimento.logradouro || "",
      complemento: estabelecimento.complemento || "",
      cep: estabelecimento.cep || "",
      numero: estabelecimento.numero || "",
      municipio: estabelecimento.municipio || "",
      uf: estabelecimento.uf || "",
      descricao: estabelecimento.descricao || "",
      horario: estabelecimento.horario || {},
      id_estabelecimento: estabelecimento.id_estabelecimento,
    },
  });

  const onSubmit = async (formData) => {
    updateEstabelecimento(formData);
  };
  const buscarCep = async (cep) => {
    try {
      const response = await PrestAPI.getCep(cep);
      if (response && !response.erro) {
        setValue("bairro", response.bairro);
        setValue("logradouro", response.logradouro);
        setValue("municipio", response.localidade);
        setValue("uf", response.uf);
        setValue("cep", response.cep);
      } else {
        toast.error("CEP não encontrado, verifique e tente novamente.");
      }
    } catch (error) {
      toast.error("Erro ao buscar CEP, tente novamente.");
    }
  };

  return (
    <div className="container-fluid">
      <h3>Meus Dados</h3>

      <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-2">
          <button className="btn btn-vk" type="submit" disabled={isUpdating}>
            {isUpdating ? "Salvando..." : "Salvar"}
          </button>
        </div>
        <div className="row g-3">
          <div className="col-md-6">
            <label className="form-label">Razão social</label>
            <input
              className="form-control"
              type="text"
              placeholder="Digite a razão social"
              {...register("razao_social")}
              disabled={!!estabelecimento.razao_social}
            />
            {errors.razao_social && (
              <span className="text-danger">{errors.razao_social.message}</span>
            )}
          </div>
          <div className="col-md-6">
            <label className="form-label">Nome fantasia</label>
            <input
              className="form-control"
              label="Nome fantasia"
              type="text"
              placeholder="Digite o nome fantasia"
              {...register("nome_fantasia")}
            />
            {errors.nome_fantasia && (
              <span className="text-danger">
                {errors.nome_fantasia.message}
              </span>
            )}
          </div>
          <div className="col-md-4">
            <label className="form-label">CNPJ</label>
            <InputMask
              className="form-control"
              type="text"
              placeholder="Digite o CNPJ"
              mask="99.999.999/9999-99"
              {...register("cnpj")}
              disabled={!!estabelecimento.cnpj}
            />
            {errors.cnpj && (
              <span className="text-danger">{errors.cnpj.message}</span>
            )}
          </div>
          <div className="col-md-4">
            <label className="form-label">Inscrição Estadual</label>
            <InputMask
              className="form-control"
              type="text"
              mask="999.999.999.999"
              placeholder="Digite a inscrição estadual"
              {...register("ie")}
              disabled={!!estabelecimento.ie}
            />
            {errors.ie && (
              <span className="text-danger">{errors.ie.message}</span>
            )}
          </div>

          <div className="col-md-4">
            <label htmlFor="segmento" className="form-label">
              Segmento
            </label>
            <select
              className="form-select"
              id="segmento"
              {...register("segmento")}
              defaultValue={estabelecimento.segmento || ""}
              disabled={!!estabelecimento.segmento}
              required
            >
              <option value="">Selecione o segmento</option>
              {categorias.map((categoria, index) => (
                <option key={index} value={categoria.nome}>
                  {categoria.nome}
                </option>
              ))}
            </select>
            {errors.segmento && (
              <span className="text-danger">{errors.segmento.message}</span>
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="col-md-8">
            <label className="form-label">Descricão</label>
            <textarea name="descrição" className="form-control" />
          </div>
        </div>
        <div className="col-md-3">
          <label className="form-label">Telefone</label>
          <InputMask
            className="form-control"
            placeholder="(99) 99999-9999"
            mask="(99) 99999-9999"
            {...register("telefone")}
          />
          {errors.telefone && (
            <span className="text-danger">{errors.telefone.message}</span>
          )}
        </div>
        <div className="col-md-4">
          <label className="form-label">Email</label>
          <input
            className="form-control"
            type="email"
            placeholder="Digite o email"
            disabled={!!estabelecimento.email}
            {...register("email")}
          />
          {errors.email && (
            <span className="text-danger">{errors.email.message}</span>
          )}
        </div>
        <div className="col-md-4">
          <label className="form-label">Contato</label>
          <input
            className="form-control"
            type="text"
            placeholder="Digite o nome do contato"
            {...register("contato")}
          />
          {errors.contato && (
            <span className="text-danger">{errors.contato.message}</span>
          )}
        </div>
        <div className="col-md-3">
          <label className="form-label">Telefone do contato</label>
          <InputMask
            className="form-control"
            type="text"
            placeholder="(99) 99999-9999"
            mask="(99) 99999-9999"
            {...register("telefone_contato")}
          />
          {errors.telefone_contato && (
            <span className="text-danger">
              {errors.telefone_contato.message}
            </span>
          )}
        </div>
        <div className="col-md-4">
          <label className="form-label">Email de contato</label>
          <input
            className="form-control"
            type="email"
            placeholder="Digite o email"
            disabled={!!estabelecimento.email}
            {...register("email_contato")}
          />
          {errors.email_contato && (
            <span className="text-danger">{errors.email_contato.message}</span>
          )}
        </div>
        <div className="col-12 mt-3 border-top">
          <h5 className="text-center fs-4">Endereço</h5>
        </div>

        {!estabelecimento.cep && (
          <div className="col-12">
            <div className="col-md-3 mb-2">
              <label className="form-label">Cep</label>
              <InputMask
                className="form-control"
                mask="99999-999"
                value={cep}
                onChange={(e) => setCep(e.target.value)}
              />
              {errors.cep && (
                <span className="text-danger">{errors.cep.message}</span>
              )}
            </div>
            <button
              className="btn btn-vk"
              onClick={() => buscarCep(cep)}
              type="button"
            >
              Buscar Cep
            </button>
          </div>
        )}

        <div className="col-md-6">
          <label className="form-label">Logradouro</label>
          <input
            className="form-control"
            type="text"
            disabled={!!estabelecimento.logradouro}
            placeholder="Digite o logradouro"
            {...register("logradouro")}
          />
          {errors.logradouro && (
            <span className="text-danger">{errors.logradouro.message}</span>
          )}
        </div>
        <div className="col-md-2">
          <label className="form-label">Número</label>
          <input
            className="form-control"
            type="text"
            disabled={!!estabelecimento.numero}
            placeholder="Digite o número"
            {...register("numero")}
          />
          {errors.numero && (
            <span className="text-danger">{errors.numero.message}</span>
          )}
        </div>
        <div className="col-md-6">
          <label className="form-label">Complemento</label>
          <input
            className="form-control"
            type="text"
            placeholder="Digite o complemento"
            {...register("complemento")}
          />
          {errors.complemento && (
            <span className="text-danger">{errors.complemento.message}</span>
          )}
        </div>
        <div className="col-md-6">
          <label className="form-label">Bairro</label>
          <input
            className="form-control"
            type="text"
            disabled={!!estabelecimento.bairro}
            placeholder="Digite o bairro"
            {...register("bairro")}
          />
          {errors.bairro && (
            <span className="text-danger">{errors.bairro.message}</span>
          )}
        </div>
        <div className="col-md-2">
          <label className="form-label">CEP</label>
          <InputMask
            className="form-control"
            type="text"
            mask="99999-999"
            disabled={!!estabelecimento.cep}
            placeholder="Digite o CEP"
            {...register("cep")}
          />
          {errors.cep && (
            <span className="text-danger">{errors.cep.message}</span>
          )}
        </div>

        <div className="col-md-4">
          <label className="form-label">Cidade</label>
          <input
            className="form-control"
            type="text"
            disabled={!!estabelecimento.municipio}
            placeholder="Digite a cidade"
            {...register("municipio")}
          />
          {errors.municipio && (
            <span className="text-danger">{errors.municipio.message}</span>
          )}
        </div>
        <div className="col-md-2">
          <label className="form-label">UF</label>
          <input
            className="form-control"
            type="text"
            disabled={!!estabelecimento.uf}
            placeholder="Digite a UF"
            {...register("uf")}
          />
          {errors.uf && (
            <span className="text-danger">{errors.uf.message}</span>
          )}
        </div>

        {/* Horarios de Atendimento */}
        <div className="row mt-2 border-top">
          <h5 className="mt-2 text-center">Horários de Atendimento</h5>
          <div className="row">
            <div className="border-1 border-dark border">
              <input
                type="checkbox"
                name="aberto"
                className="form-check-input rounded-circle border border-2 border-dark"
                style={{ width: "20px", height: "20px" }}
              />
              <label className="form-label mx-2">
                Loja Web ficara aberto independente do horário de funcionamento
              </label>
            </div>
          </div>
          <HorariosAtendimento
            horarios={estabelecimento.horario}
            onSaveHorarios={(novosHorarios) =>
              setValue("horario", novosHorarios)
            }
          />
        </div>
        {errors.horario && (
          <span className="text-danger">{errors.horario.message}</span>
        )}
      </form>
    </div>
  );
};

export default FormCadastro;
