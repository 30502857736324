import React, { Component } from "react";
import PropTypes from "prop-types";
class Turno extends Component {
  static propTypes = {
    turno: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
  };

  state = {
    horario: [],
  };

  componentDidMount = () => {
    let horario = this.props.turno.horario;

    let arr = horario.split("|");

    this.setState({ horario: arr });
  };

  render() {
    const { turno, index } = this.props;
    const { horario } = this.state;
    return (
      <div key={index}>
        <div className="row">
          <div className="col-sm-6">
            <label>
              <b> {turno.turno}° turno </b>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <label htmlFor="horaInicio" className="form-label">
              Hora Início:
            </label>
            <input
              type="time"
              className="form-control input-md"
              name="horaInicio"
              readOnly
              defaultValue={horario[0]}
            />
          </div>
          <div className="col-sm-6">
            <label htmlFor="horaFim" className="form-label">
              Hora Fim:
            </label>
            <input
              type="time"
              className="form-control input-md"
              name="horaFim"
              readOnly
              defaultValue={horario[1]}
            />
          </div>
        </div>
        <hr />
      </div>
    );
  }
}

export default Turno;
