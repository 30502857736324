import React, { useState } from "react";
import ComponentItem from "./ComponentItem";
import PropTypes from "prop-types";
import { Accordion, Card } from "react-bootstrap";

import NewCategoriaComplement from "./NewCategoriaComplement";

import "./styles.css";
import CategoriaComplementList from "./CategoriaComplementList";

function CatComponent({
  idProduto,
  idEstabelecimento,
  getProdutoComplemento,
  complementos,
  selectComp,
}) {
  const [ocultaCategoria, setOcultaCategoria] = useState(true);
  const [statusCat, setStatusCat] = useState("Ativo");
  const [statusComp, setStatusComp] = useState("Ativo");
  const [activeKeys, setActiveKeys] = useState([]);

  const handleAccordionToggle = (id_grupo) => {
    setActiveKeys((prevActiveKeys) => {
      const isActive = prevActiveKeys.includes(id_grupo);
      return isActive
        ? prevActiveKeys.filter((key) => key !== id_grupo)
        : [...prevActiveKeys, id_grupo];
    });
  };

  const showCategoriaComponent = () => {
    setOcultaCategoria(false);
  };

  const hideCategoriaComponent = () => {
    setOcultaCategoria(true);
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-between align-items-center mt-4 mb-3">
        <div className="col-sm-3 align-content-center">
          <button
            onClick={
              ocultaCategoria ? showCategoriaComponent : hideCategoriaComponent
            }
            className={`${
              ocultaCategoria ? "btn btn-vk w-100" : "btn-outline-vk w-100"
            }`}
            style={ocultaCategoria ? { outline: "none" } : {}}
          >
            {ocultaCategoria ? "Adicionar grupo (Título)" : "X"}
          </button>
        </div>
        <div className="col-sm-4 row">
          <label className="col-form-label col text-end">Status Grupo:</label>
          <div className="col align-content-center">
            <select
              className="form-select input-sm"
              id="statusFiltro"
              onChange={(e) => {
                setStatusCat(e.target.value);
              }}
            >
              <option value="Ativo">Ativo</option>
              <option value="Inativo">Inativo</option>
              <option value="Todos">Todos</option>
            </select>
          </div>
        </div>
        <div className="col-sm-4 row">
          <label className="col-form-label col text-end">
            Status Complemento:
          </label>
          <div className="col align-content-center">
            <select
              className="form-select input-sm"
              id="statusFiltro"
              onChange={(e) => {
                setStatusComp(e.target.value);
              }}
            >
              <option value="Ativo">Ativo</option>
              <option value="Inativo">Inativo</option>
              <option value="Todos">Todos</option>
            </select>
          </div>
        </div>
      </div>

      {!ocultaCategoria && (
        <NewCategoriaComplement
          idEstabelecimento={idEstabelecimento}
          idProduto={idProduto}
          ocultaCategoriaComponent={hideCategoriaComponent}
          getCatComplementos={getProdutoComplemento}
        />
      )}

      <Accordion activeKey={activeKeys}>
        {complementos &&
          complementos
            .filter((categoria) => {
              if (statusCat === "Ativo") {
                return categoria.status_categoria === 1;
              } else if (statusCat === "Inativo") {
                return categoria.status_categoria === 0;
              }
              return true;
            })
            .map((categoria) => (
              <div key={categoria.id_categoria_complemento}>
                <Card.Header
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAccordionToggle(categoria.id_categoria_complemento);
                  }}
                >
                  <CategoriaComplementList
                    categoria={categoria}
                    getCatComplementos={getProdutoComplemento}
                  />
                </Card.Header>

                <Accordion.Collapse
                  eventKey={categoria.id_categoria_complemento}
                  in={activeKeys.includes(categoria.id_categoria_complemento)}
                >
                  <Card.Body className="mx-1 rounded rounded-bottom">
                    {categoria.itens &&
                      categoria.itens
                        .filter((item) => {
                          if (statusComp === "Ativo") {
                            return item.status_complemento === 1;
                          } else if (statusComp === "Inativo") {
                            return item.status_complemento === 0;
                          }
                          return true;
                        })
                        .map((item) => (
                          <ComponentItem
                            key={item.id_complemento}
                            ocultar={true}
                            item={item}
                            getCatComplementos={getProdutoComplemento}
                            selectComp={selectComp}
                          />
                        ))}
                  </Card.Body>
                </Accordion.Collapse>
              </div>
            ))}
      </Accordion>
    </div>
  );
}

export default CatComponent;
