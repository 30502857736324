import { useState } from 'react';
import {apiClient} from "../../../service/api/PrestAPI";
export const useAddBanner = (storeUid) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const addBanner = async (bannerData) => {
    setLoading(true);
    try {
      const { data } = await apiClient.post(`/store/${storeUid}/banner`, bannerData);
      return data;
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { addBanner, loading, error };
};
