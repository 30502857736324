import React, { Component } from "react";
import PropTypes from "prop-types";
import "./styles.css";
import Turno from "./Turno";
import ModalAgendaComponent from "react-modal";
import ModalAgenda from "./ModalAgenda";
import * as PrestAPI from "../../../service/api/PrestAPI";
import { Accordion, Card } from "react-bootstrap";
import { toast } from "react-hot-toast";

class AgendaComponent extends Component {
  static propTypes = {
    produto: PropTypes.object.isRequired,
    agenda: PropTypes.object.isRequired,
    disponibilidade: PropTypes.array.isRequired,
    numero: PropTypes.number,
    setAgenda: PropTypes.func,
    isNovaAgenda: PropTypes.func,
    editNovaAgenda: PropTypes.func,
    tipo: PropTypes.string.isRequired,
    complemento: PropTypes.object,
  };

  state = {
    dias: "",
    disponibilidade: [],
    isComponentModalVisible: false,
    activeKeys: [],
  };

  verificaDias = (dias) => {
    const diasSelecionados = {
      dom: dias.includes("dom"),
      seg: dias.includes("seg"),
      ter: dias.includes("ter"),
      qua: dias.includes("qua"),
      qui: dias.includes("qui"),
      sex: dias.includes("sex"),
      sab: dias.includes("sab"),
    };
    this.setState({ diasSelecionados });
  };

  handleAccordionToggle = (numero) => {
    this.setState((prevState) => {
      const { activeKeys } = prevState;
      const isActive = activeKeys.includes(numero);

      return {
        activeKeys: isActive
          ? activeKeys.filter((key) => key !== numero)
          : [...activeKeys, numero],
      };
    });
  };

  showModal = () => {
    this.setState({ isComponentModalVisible: true });
    this.props.editNovaAgenda();
  };

  hideModal = () => {
    this.setState({ isComponentModalVisible: false });
  };

  componentDidMount = () => {
    let dias = this.props.agenda.dias;
    let disponibilidade = this.props.disponibilidade;
    this.setState({ dias, disponibilidade });

    this.verificaDias(dias);
  };

  handleDelete = (descricao) => {
    const { tipo, produto, complemento, setAgenda } = this.props;
    if (tipo === "produto") {
      PrestAPI.del(
        "ProdutoAgenda",
        `?idProduto=${produto.id_produto}&descricao=${descricao}`
      )
        .then((result) => {
          if (result.status === 200) {
            PrestAPI.get(
              `Produto?id_produto=${produto.id_produto}&modo=retaguarda`
            )
              .then((res) => {
                let agenda = res.produtos[0].agenda || [];
                setAgenda(agenda);
                toast.success("Agenda excluída com sucesso!");
              })
              .catch((err) => {
                console.error(err);
                toast.error("Erro ao buscar agenda do produto.");
              });
          } else {
            toast.error("Erro ao excluir a agenda.");
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error("Erro ao excluir a agenda.");
        });
    } else if (tipo === "complemento") {
      PrestAPI.del(
        "ProdutoComplementoItemAgenda",
        `?idComplemento=${complemento.id_complemento}&descricao=${descricao}`
      )
        .then((result) => {
          if (result.status === 200) {
            PrestAPI.get(
              "ProdutoComplemento",
              `estab=${produto.id_estabelecimento}&idProduto=${produto.id_produto}&modo=retaguarda`
            )
              .then((res) => {
                let arrComp = [];
                res.complementos.forEach((cat) => {
                  if (
                    cat.id_categoria_complemento ===
                    complemento.id_categoria_complemento
                  ) {
                    arrComp = cat.itens.filter(
                      (item) =>
                        item.id_complemento === complemento.id_complemento
                    );
                  }
                });
                let agenda = arrComp[0].agenda || [];
                setAgenda(agenda);
                toast.success("Agenda excluída com sucesso!");
              })
              .catch((err) => {
                console.error(err);
                toast.error("Erro ao buscar agenda do complemento.");
              });
          } else {
            toast.error("Erro ao excluir a agenda.");
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error("Erro ao excluir a agenda.");
        });
    }
  };

  render() {
    const { agenda, numero, produto, complemento, tipo } = this.props;
    const { disponibilidade, isComponentModalVisible, activeKeys } = this.state;
    return (
      <div className="container-fluid">
        <Accordion activeKey={activeKeys}>
          <Card className="mt-3">
            <Card.Header
              style={{ cursor: "pointer" }}
              onClick={() => this.handleAccordionToggle(numero)}
            >
              <div className="row my-2">
                <div className="col-md-7 text-center">
                  <h3>{agenda.descricao}</h3>
                </div>
                <div className="col-md-3">
                  <button
                    className="btn btn-vk w-100 mt-2"
                    id={`${agenda.descricao}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.showModal();
                    }}
                    type="button"
                  >
                    Editar agenda
                  </button>
                </div>
                <div className="col-md-2">
                  <button
                    className="btn btn-outline-vk w-100 mt-2"
                    id={`Exc${agenda.descricao}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.handleDelete(agenda.descricao);
                    }}
                    type="button"
                  >
                    Excluir
                  </button>
                </div>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey={numero}>
              <Card.Body>
                <div className="container-fluid">
                  <label className="form-label w-100">Dias da semana:</label>
                  <div className="btn-group z-0">
                    {["dom", "seg", "ter", "qua", "qui", "sex", "sab"].map(
                      (dia, index) => (
                        <label
                          key={index}
                          className={`btn btn-outline-vk btn-md ${
                            agenda.dias.includes(dia) ? "active" : ""
                          }`}
                          id={`${dia}${numero}`}
                          style={{ pointerEvents: "none" }}
                        >
                          <input
                            type="checkbox"
                            className="btn-check"
                            name={dia}
                            id="dia"
                          />
                          {dia.charAt(0).toUpperCase()}
                        </label>
                      )
                    )}
                  </div>
                  <hr />
                  {disponibilidade.map((turno, index) => (
                    <Turno key={index} index={index} turno={turno} />
                  ))}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        {/* -------------------MODAL ---------------------------- */}
        <ModalAgendaComponent
          ariaHideApp={false}
          isOpen={isComponentModalVisible}
          style={customStyles}
          contentLabel="Modal Agendamento"
          onRequestClose={this.hideModal}
        >
          <ModalAgenda
            hideModal={this.hideModal}
            modo={tipo}
            produto={produto}
            setAgenda={this.props.setAgenda}
            item={agenda}
            complemento={complemento}
          />
        </ModalAgendaComponent>
      </div>
    );
  }
}

export default AgendaComponent;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "5px",
    height: "fit-content",
    maxHeight: "100%",
    overflowY: "auto",
  },
  overlay: {
    position: "fixed",
    backgroundColor: "rgba(0.30, 0.20, 0, 0.20)",
  },
};
